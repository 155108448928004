// @flow
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes'

import transDomain from './translations/index.translations'

import * as Form from 'components/Form'
import withForm, { WithFormProps } from 'components/Form/Helpers/FormHOC'
import Steps from 'components/Steps'

type Props = WithFormProps | {
    values: {
        state: ?number,
        address: ?string,
        city: ?string,
        zipcode: ?string
    }
}

function Step2 (props: Props) {
    const { theme } = props
    const { t } = useTranslation(transDomain)
    return (
        <>
            <Steps step={2} description={t('description')} />

            <Form.Row>
                <Form.Field
                    // error={props.errors.address || null}
                    defaultValue={props.values.address || null}
                    onChange={value =>
                        props.handleFieldChange('address', value)
                    }
                    type='text'
                    label={t('field.address.label')}
                    placeholder={t('field.address.placeholder')}
                />
            </Form.Row>
            <Form.Row>
                <Form.Field label={t('field.state.label')}
                    placeholder={t('field.state.placeholder')}
                    trans={t}
                    onChange={value => props.handleFieldChange('state', value)}
                    defaultValue={props.values.state}
                    type='text'
                />
            </Form.Row>
            <Form.Row>
                <Form.RowColumns>
                    <Form.RowColumn>
                        <Form.Field
                            // error={props.errors.zipcode || null}
                            defaultValue={props.values.city || null}
                            onChange={value => props.handleFieldChange('city', value)}
                            type='text'
                            label={t('field.city.label')}
                            placeholder={t('field.city.placeholder')}
                        />
                    </Form.RowColumn>
                    <Form.RowColumn>
                        <Form.Field
                            // error={props.errors.zipcode || null}
                            defaultValue={props.values.zipcode || null}
                            onChange={value => props.handleFieldChange('zipcode', value)}
                            type='text'
                            label={t('field.zipcode.label')}
                            placeholder={t('field.zipcode.placeholder')}
                        />
                    </Form.RowColumn>
                </Form.RowColumns>
            </Form.Row>
            <Form.Row>
                <Form.Submit
                    isLoading={props.isLoading}
                    label={t('label.continueToStep3')}
                    style={{ background: theme.background, color: theme.color, marginTop: '1em' }}
                />
            </Form.Row>
        </>
    )
}

export default withForm()(withTheme(supportedThemes)(Step2))
