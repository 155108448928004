import React from 'react'

import styles from './styles.module.scss'
import MainLogo from 'components/MainLogo'
import MediaQuery from 'react-responsive'
import PromoInfoSlider from 'components/PromoInfoSlider'

import bg from './images/background.jpg'

const StepLayout = ({
    children,
    message
}) => {
    return (
        <>
            <div className={styles.container}>
                <div className={styles.leftSide} style={{ backgroundImage: `url(${bg})` }}>
                    <div className={styles.mainLogo}>
                        <MainLogo />
                    </div>
                    <h2 className={styles.title}>{message}</h2>
                </div>
                <div className={styles.body}>
                    <MediaQuery maxWidth={1024}>
                        <div className={styles.mainLogo}>
                            <MainLogo />
                        </div>
                    </MediaQuery>
                    {children}
                </div>
            </div>
            <MediaQuery maxWidth={1023}>
                <PromoInfoSlider />
            </MediaQuery>
        </>
    )
}

export default StepLayout
