
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import nsdddfaf6c9fedc969783ed2212c2961d6en_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', 'dddfaf6c9fedc969783ed2212c2961d6', flattenObject(nsdddfaf6c9fedc969783ed2212c2961d6en_US), true, true);
        
            import nsdddfaf6c9fedc969783ed2212c2961d6en from './en.yaml'
            i18n.addResourceBundle('en', 'dddfaf6c9fedc969783ed2212c2961d6', flattenObject(nsdddfaf6c9fedc969783ed2212c2961d6en), true, true);
        
        export default 'dddfaf6c9fedc969783ed2212c2961d6';
    