import React from 'react'
import OfferSummary from 'components/OfferSummary'
import SwiperContainer from 'components/SwiperContainer'

import Color from 'color'

import styles from './styles.module.scss'

const PromoInfoSlider = () => {
    return (
        <div className={styles.container} style={{ backgroundColor: Color('#E1ECF0').fade(0.4) }}>
            <div className={styles.sliderContainer}>
                <SwiperContainer disableNavigation>
                    <span>
                        <OfferSummary
                            title={'New Patient Offer!'}
                            message={'Get a Dental Exam & Cleaning For Only'}
                            amount={'$149'}
                            small
                            shortMessage={<>Regular value <strong>$529</strong></>}
                />
                    </span>
                    <span>
                        <OfferSummary
                            title={'New Patient Offer!'}
                            message={'Receive a Full Dental Cleaning For Only'}
                            amount={'$59'}
                            small
                            shortMessage={<>Regular value <strong>$115</strong></>} />
                    </span>
                </SwiperContainer>
            </div>
        </div>
    )
}

export default PromoInfoSlider
