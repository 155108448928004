// @flow
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'

import transDomain from './translations/index.translations'

import * as Form from 'components/Form'
import withForm, { WithFormProps } from 'components/Form/Helpers/FormHOC'
import Steps from 'components/Steps'

import supportedThemes from './themes/__supportedThemes'

type Props = WithFormProps | {
    values: {
        offering: ?string,
        firstName: ?string,
        lastName: ?string,
        emailAddress: ?string,
        daytimePhone: ?string
    }
}

function Step1 (props: Props) {
    const { theme } = props
    const { t } = useTranslation(transDomain)

    return (
        <>
            <Steps step={1} description={t('description')} title={t('section.contactInformation')} />
            <Form.Row>
                <Form.Field label={t('field.offering.label')}>
                    <Form.Dropdown
                        options={['Receive a Dental Exam for only $149', 'city1', 'city2', 'city3', 'city4']}
                        trans={t}
                        defaultValue={props.values.offering || null}
                        onChange={value => props.handleFieldChange('offering', value)}
                        label={t('field.offering.label')}
                        placeholder={t('field.offering.placeholder')}
                    />
                </Form.Field>
            </Form.Row>

            <Form.Row>
                <Form.RowColumns>
                    <Form.RowColumn>
                        <Form.Field
                            // error={props.errors.firstName || null}
                            defaultValue={props.values.firstName || null}
                            onChange={value => props.handleFieldChange('firstName', value)}
                            type='text'
                            label={t('field.firstName.label')}
                            placeholder={t('field.firstName.placeholder')}
                        />
                    </Form.RowColumn>
                    <Form.RowColumn>
                        <Form.Field
                            // error={props.errors.lastName || null}
                            defaultValue={props.values.lastName || null}
                            onChange={value => props.handleFieldChange('lastName', value)}
                            type='text'
                            label={t('field.lastName.label')}
                            placeholder={t('field.lastName.placeholder')}
                        />
                    </Form.RowColumn>
                </Form.RowColumns>
            </Form.Row>

            <Form.Row>
                <Form.Field
                    // error={props.errors.email || null}
                    defaultValue={props.values.email || null}
                    onChange={value =>
                        props.handleFieldChange('email', value)
                    }
                    type='email'
                    label={t('field.emailAddress.label')}
                    placeholder={t('field.emailAddress.placeholder')}
                />
            </Form.Row>

            <Form.Row>
                <Form.RowColumns>
                    <Form.RowColumn>
                        <Form.Field
                            // error={props.errors.dayPhone || null}
                            defaultValue={props.values.daytimePhone || null}
                            onChange={value =>
                                props.handleFieldChange('daytimePhone', value)
                            }
                            type='phone'
                            required={true}
                            label={t('field.daytimePhone.label')}
                            placeholder={t('field.daytimePhone.placeholder')}
                        />
                    </Form.RowColumn>
                </Form.RowColumns>
            </Form.Row>

            <Form.Row>
                <Form.Submit
                    isLoading={props.isLoading}
                    label={t('continueToStep2')}
                    style={{ background: theme.background, color: theme.color, marginTop: '1em' }}
                />
            </Form.Row>
        </>
    )
}

export default withForm()(withTheme(supportedThemes)(Step1))
