import * as React from 'react'

import withTheme from 'hoc/withTheme'

import supportedThemes from './themes/__supportedThemes.js'

import styles from './styles.module.scss'

export const HeroVideo = ({ theme }) => {
    return (
        <div className={styles.container}
            style={{
                '--hero-mask': `url(${theme.heroMask})`
            }}>
            <div className={styles.slide}
                style={{
                    '--background-image': `url(${theme.background})`
                }}
            />
        </div>
    )
}

export default withTheme(supportedThemes)(HeroVideo)
