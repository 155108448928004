
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns63dc53ba23d0f94d5c8bc8bc9a832fe7en_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', '63dc53ba23d0f94d5c8bc8bc9a832fe7', flattenObject(ns63dc53ba23d0f94d5c8bc8bc9a832fe7en_US), true, true);
        
            import ns63dc53ba23d0f94d5c8bc8bc9a832fe7en from './en.yaml'
            i18n.addResourceBundle('en', '63dc53ba23d0f94d5c8bc8bc9a832fe7', flattenObject(ns63dc53ba23d0f94d5c8bc8bc9a832fe7en), true, true);
        
        export default '63dc53ba23d0f94d5c8bc8bc9a832fe7';
    