// @flow
import * as React from 'react'
import withTheme from 'hoc/withTheme'
import RegistrationForm from 'components/RegistrationForm'
import cn from 'classnames'
import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'
import MediaQuery from 'react-responsive'

import type { Theme } from './themes/__supportedThemes'

import MainLogo from 'components/MainLogo'

type Props = {
    theme: Theme
}

function Hero (props: Props) {
    const { theme } = props
    return (
        <div className={styles.container}>
            <div className={cn(styles.registerForm, styles.rightSide)}>
                <MainLogo />
                <span className={styles.voucherMessage} style={{ color: theme.primaryColor }}>
                    Great offers for <strong>new patients</strong> in your area
                </span>
                <span className={styles.promoMessage}>
                    Enter the promo code found on your mailer to redeem your new patient offer.
                </span>
                <RegistrationForm />
                <MediaQuery minWidth={1023}>
                    <div>
                        <em className={styles.mark}>“New patient appointments will fill up quickly, so act fast before this opportunity passes. If you are looking for a new dentist, or are interested in our practice, this is the time.”</em>
                        <div className={styles.message}>
                            <span className={styles.shortMessage}>
                                <strong className={styles.signature}>
                                    Dr. Jonathan Smitherson
                                </strong>
                                Owner, and Primary Dentist
                            </span>
                        </div>
                    </div>
                </MediaQuery>
            </div>
        </div>
    )
}

export default withTheme(supportedThemes)(Hero)
