// @flow
import * as React from 'react'
import cn from 'classnames'
import OfferSummary from 'components/OfferSummary'

import { withTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'

import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'

function PromoInfo (props) {
    const { theme } = props
    return (
        <div
            className={cn(styles.container, props.className)}
            style={{
                '--background-color': theme.titleColor,
                '--plus-icon-bg': theme.plusIconBg,
                '--value-color': theme.valueColor,
                '--title-color': theme.titleColor
            }}>
            <div className={styles.leftSide}>
                <OfferSummary
                    title={'New Patient Special Offer!'}
                    message={'Get a Dental Exam & Cleaning For Only'}
                    amount={'$149'}
                    shortMessage={<>Regular value <strong>$529</strong></>}
                />
            </div>
            <div className={styles.bubbleBorder} style={{
                backgroundColor: theme.bubbleBorder
            }}>
                <div className={styles.bubble} style={{
                    backgroundColor: theme.bubbleBg
                }}>
                </div>
            </div>
            <div className={styles.rightSide}>
                <OfferSummary
                    title={'New Patient Special Offer!'}
                    message={'Receive a Full Dental Cleaning For Only'}
                    amount={'$59'}
                    shortMessage={<>Regular value <strong>$115</strong></>}
                />
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(PromoInfo))
