/* eslint-disable react-hooks/exhaustive-deps */
// @flow

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes'
// import usePrevius from 'hook/usePrevius'
import { Redirect } from 'react-router-dom'
import transDomain from './translations/index.translations'
import { Scrollbars } from 'react-custom-scrollbars'

import MemberForm from 'components/MemberForm'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'

import * as Form from 'components/Form'
import withForm, { WithFormProps } from 'components/Form/Helpers/FormHOC'
import Steps from 'components/Steps'

import ModalContainer from 'components/ModalContainer'
import OfferSummary from 'components/OfferSummary'

import styles from './styles.module.scss'

type Props = WithFormProps | {
    values: {
        patients: {}
        // { uuid: '', ... }
    }
}
function Step3 (props: Props) {
    const { theme, showModal } = props
    const { t } = useTranslation(transDomain)
    const [patients, setPatients] = React.useState({}) // props.values.patients ||
    const [endSession, setEndSession] = React.useState(false)
    // const prevCount = usePrevius(_.keys(patients).length)

    const addPatient = () => setPatients(state => {
        return { ...state, [uuidv4()]: {} }
    })

    const removePatient = uuid => setPatients(state => {
        const currentValue = _.omit(_.cloneDeep(patients), uuid)
        props.handleFieldChange('patients', currentValue)
        return currentValue
    })

    const onSave = uuid => {
        const currentValue = _.cloneDeep(patients)
        currentValue[uuid] = patients[uuid]
        currentValue[uuid].saved = true
        props.handleFieldChange('patients', currentValue)
        setPatients(_.cloneDeep(currentValue))
    }

    const handleFieldChange = uuid => {
        return (field, value) => {
            const currentValue = _.cloneDeep(patients)
            currentValue[uuid][field] = value
            setPatients(currentValue)
        }
    }

    const patientsKeys = _.keys(patients)

    // React.useEffect(() => {
    //  if (!endSession && prevCount < _.keys(patients).length) {
    //     window.scrollTo(0, document.body.scrollHeight + 500)
    // }
    // }, [ patientsKeys.length, endSession ])

    if (endSession) {
        return <Redirect to={`${props.location.pathname.replace('/step-3', '')}${props.location.search}`} push />
    }

    return (
        <>
            <Steps step={3} description={t('description')} title={t('section.contactInformation')} />

            <ModalContainer show={showModal} onClickOverlay={() => console.log('close')}>
                <div className={styles.modalBody}>
                    <Scrollbars
                        autoHeight
                        autoHeightMin={'100%'}
                        universal={true}
                        autoHeightMax={'calc(100vh - 60px)'}
                        thumbMinSize={30}>
                        <h3 style={{ color: theme.colors.primary }}>
                            Your registration is complete, and you have secured the following incentive.
                        </h3>
                        <div className={styles.voucherValue} style={{ borderColor: theme.colors.primary }}>
                            <OfferSummary
                                title={'Get a dental exam & cleaning for only'}
                                amount={'$159'}
                                shortMessage={<>Regular value <strong>$529</strong></>}
                    />
                        </div>
                        <span style={{ color: theme.colors.primary }} className={styles.voucherMessage}>
                            We will be in touch with you to set up your dental appointment. Looking forward to meeting you!
                        </span>
                        <button
                            style={{ background: theme.background, color: theme.color, cursor: 'pointer' }}
                            onClick={() => setEndSession(true)}
                        >
                            End Your Session
                        </button>
                    </Scrollbars>
                </div>
            </ModalContainer>

            <div>
                {patientsKeys.map(uuid => <MemberForm
                    saved={patients[uuid].saved}
                    index={uuid}
                    key={uuid}
                    values={patients[uuid]}
                    onRemove={() => removePatient(uuid)}
                    onSave={() => { onSave(uuid) }}
                    handleFieldChange={handleFieldChange(uuid)}
                    />)}
            </div>

            <span className={styles.addAnother} style={{
                '--primary-color': theme.colors.primary,
                cursor: 'pointer'
            }} onClick={addPatient}>
                {patientsKeys.length > 0 ? '+ Add another' : '+ Add family member'}
            </span>

            <Form.Row>
                <Form.Submit
                    isLoading={showModal ? false : props.isLoading}
                    label={t('continueToStep2')}
                    onClick={(e) => { e.preventDefault() }}
                    style={{ background: theme.background, color: theme.color, marginTop: '1em' }}
                />
            </Form.Row>
        </>
    )
}

export default withForm()(withTheme(supportedThemes)(Step3))
