// @flow
import * as React from 'react'
import { withRouter } from 'react-router-dom'
import cn from 'classnames'

import { Store } from 'Store'

import Hero from 'components/Hero'
import HeroVideo from 'components/HeroVideo'

import styles from './styles.module.scss'
import PromoInfo from 'components/PromoInfo'
import PromoInfoSlider from 'components/PromoInfoSlider'

import MediaQuery from 'react-responsive'

export default withRouter(({
    history
}) => {
    const { dispatch } = React.useContext(Store)
    React.useMemo(() => history.listen(() => {
        if (window.location.pathname.split('/').length === 2) {
            dispatch({
                type: 'SET_LEAD',
                payload: null
            })
        }
    }), [ history, dispatch ])

    return (
        <div className={styles.container}>
            <div className={styles.leftSide}>
                <HeroVideo />
                <MediaQuery minWidth={1024}>
                    <div className={styles.cashbackAndRatesFooter}>
                        <PromoInfo />
                    </div>
                </MediaQuery>
            </div>
            <div className={cn(styles.registerForm, styles.rightSide)}>
                <Hero />
                <MediaQuery maxWidth={1023}>
                    <PromoInfoSlider />
                </MediaQuery>
            </div>
        </div>
    )
})
