import React from 'react'
import styles from './styles.module.scss'
import cn from 'classnames'
import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes.js'

function OfferSummary ({
    title,
    message,
    amount,
    shortMessage,
    theme,
    small
}) {
    return (
        <div style={{
            '--background-color': theme.titleColor,
            '--value-color': theme.valueColor,
            '--title-color': theme.titleColor
        }} className={cn({
            [styles.small]: small
        })}>
            <strong className={styles.title}>
                {title}
            </strong>
            {message &&
                <span className={styles.message}>
                    {message}
                </span>
            }
            <span className={styles.amount}>{amount}</span>
            <span className={cn(styles.message, styles.shortMessage)}>
                {shortMessage}
            </span>
        </div>
    )
}

export default withTheme(supportedThemes)(OfferSummary)
