import React from 'react'

import styles from './styles.module.scss'
import * as Form from 'components/Form'

import withTheme from 'hoc/withTheme'
import { useTranslation } from 'react-i18next'

import supportedThemes from './themes/__supportedThemes'
import transDomain from './translations/index.translations'

const MemberForm = ({ theme, onSave, onRemove, handleFieldChange, values, saved }) => {
    const { t } = useTranslation(transDomain)
    return (
        <div className={styles.container}>
            {saved &&
            <div className={styles.header}>
                <span className={styles.name}>{values.firstName} {values.lastName}</span>
                <div className={styles.remove} style={{
                    color: theme.colors.secondary
                }} onClick={onRemove}>
                            remove
                </div>
            </div>
            }
            {!saved &&
                <div className={styles.formContainer}>
                    <Form.Row>
                        <Form.RowColumns>
                            <Form.RowColumn>
                                <Form.Field
                                // error={props.errors.firstName || null}
                                    defaultValue={values.firstName || null}
                                    onChange={value => handleFieldChange('firstName', value)}
                                    type='text'
                                    label={t('field.firstName.label')}
                                    placeholder={t('field.firstName.placeholder')}
                            />
                            </Form.RowColumn>
                            <Form.RowColumn>
                                <Form.Field
                                // error={props.errors.lastName || null}
                                    defaultValue={values.lastName || null}
                                    onChange={value => handleFieldChange('lastName', value)}
                                    type='text'
                                    label={t('field.lastName.label')}
                                    placeholder={t('field.lastName.placeholder')}

                            />
                            </Form.RowColumn>
                        </Form.RowColumns>
                    </Form.Row>
                    <Form.Row>
                        <Form.RowColumns>
                            <Form.RowColumn>
                                <Form.Field
                                    type='text'
                                    defaultValue={values.city || null}
                                    onChange={value => handleFieldChange('city', value)}
                                    label={t('field.city.label')}
                                    placeholder={t('field.city.placeholder')}
                                />
                            </Form.RowColumn>
                            <Form.RowColumn>
                                <Form.Field
                                    type='text'
                                    defaultValue={values.state || null}
                                    onChange={value => handleFieldChange('state', value)}
                                    label={t('field.state.label')}
                                    placeholder={t('field.state.placeholder')}
                                />
                            </Form.RowColumn>
                            <Form.RowColumn>
                                <div className={styles.saveBtnContainer}>
                                    <div onClick={onSave} className={styles.saveBtn} style={{ '--primary-color': theme.colors.secondary }}>
                                        save
                                    </div>
                                </div>
                            </Form.RowColumn>
                        </Form.RowColumns>
                    </Form.Row>
                </div>
           }
        </div>
    )
}

export default withTheme(supportedThemes)(MemberForm)
